export var AppType = {
    ACCOMPLISHMENTS: "ACCOMPLISHMENTS",
    FEEDBACK: "FEEDBACK"
};
export var CodeBranchStatus = {
    ACTIVE: "ACTIVE",
    ARCHIVED: "ARCHIVED"
};
export var CodeContributorType = {
    USER: "USER",
    BOT: "BOT",
    ORGANIZATION: "ORGANIZATION",
    MANNEQUIN: "MANNEQUIN"
};
export var CodeFileChangeType = {
    ADDED: "ADDED",
    MODIFIED: "MODIFIED",
    DELETED: "DELETED",
    RENAMED: "RENAMED",
    COPIED: "COPIED",
    UNCHANGED: "UNCHANGED"
};
export var CodePullRequestStatus = {
    DRAFT: "DRAFT",
    OPEN: "OPEN",
    MERGED: "MERGED",
    CLOSED: "CLOSED"
};
export var CodePullRequestReviewStatus = {
    COMMENTED: "COMMENTED",
    APPROVED: "APPROVED",
    CHANGES_REQUESTED: "CHANGES_REQUESTED",
    DISMISSED: "DISMISSED"
};
export var CompanyStatus = {
    PENDING_SETUP: "PENDING_SETUP",
    ONBOARDING: "ONBOARDING",
    ACTIVE: "ACTIVE"
};
export var CompanyInviteStatus = {
    pending: "pending",
    accepted: "accepted",
    rejected: "rejected",
    cancelled: "cancelled"
};
export var ConversationParticipantType = {
    USER: "USER",
    BOT: "BOT"
};
export var EmployeeAccomplishmentJobStatus = {
    PENDING: "PENDING",
    RUNNING: "RUNNING",
    GENERATING: "GENERATING",
    ENRICHING: "ENRICHING",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
    CANCELLED: "CANCELLED"
};
export var EmployeeFeedbackVisibility = {
    MANAGER_ONLY: "MANAGER_ONLY",
    RECIPIENT_AND_MANAGERS: "RECIPIENT_AND_MANAGERS",
    PUBLIC: "PUBLIC"
};
export var EmployeeFeedbackReviewStatus = {
    QUEUED: "QUEUED",
    PENDING_RESPONSE: "PENDING_RESPONSE",
    COMPLETED: "COMPLETED",
    EXPIRED: "EXPIRED"
};
export var EmployeeFeedbackReviewType = {
    MANAGER_REVIEW: "MANAGER_REVIEW"
};
export var EmployeeRelationshipScore_ConversationMessageType = {
    THREAD: "THREAD",
    MESSAGE: "MESSAGE"
};
export var FeedbackManagerReviewType = {
    REVIEW_ALL: "REVIEW_ALL",
    REVIEW_SENSITIVE: "REVIEW_SENSITIVE",
    REVIEW_NONE: "REVIEW_NONE"
};
export var IntegrationType = {
    CALENDAR: "CALENDAR",
    CHAT: "CHAT",
    CODE_REPOSITORY: "CODE_REPOSITORY",
    CRM: "CRM",
    CUSTOMER_SUPPORT: "CUSTOMER_SUPPORT",
    DESIGN: "DESIGN",
    DOCUMENT_MANAGEMENT: "DOCUMENT_MANAGEMENT",
    EMAIL: "EMAIL",
    HRIS: "HRIS",
    MEETING: "MEETING",
    PROJECT_MANAGEMENT: "PROJECT_MANAGEMENT",
    VIRTUAL_OFFICE: "VIRTUAL_OFFICE"
};
export var InstallationAccessTokenType = {
    oauth: "oauth",
    personal_access_token: "personal_access_token",
    merge_dev: "merge_dev"
};
export var IntegrationInstallationStatus = {
    pending_configuration: "pending_configuration",
    active: "active",
    archived: "archived",
    warning: "warning",
    error: "error",
    demo: "demo"
};
export var IntegrationInstallationSyncJobStatus = {
    pending: "pending",
    running: "running",
    success: "success",
    failed: "failed"
};
export var MeetingAttendeeType = {
    INTERNAL: "INTERNAL",
    EXTERNAL: "EXTERNAL",
    BOT: "BOT"
};
export var ScheduledMeetingType = {
    unknown: "unknown",
    internal: "internal",
    external: "external",
    ooo: "ooo"
};
export var ScheduledMeetingAtteendeeRSVPStatus = {
    declined: "declined",
    accepted: "accepted",
    tentative: "tentative",
    awaiting_reply: "awaiting_reply",
    unknown: "unknown"
};
export var AlertType = {
    DAILY_KEY_EVENTS: "DAILY_KEY_EVENTS",
    METRIC_ANOMALY_ALERT: "METRIC_ANOMALY_ALERT",
    TEAM_WEEKLY_REPORT: "TEAM_WEEKLY_REPORT",
    TEAM_DAILY_SCHEDULE: "TEAM_DAILY_SCHEDULE"
};
export var AnalyticsReportStatus = {
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    COMPLETE: "COMPLETE",
    FAILED: "FAILED"
};
export var AnalyticsReportRowStatus = {
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    COMPLETE: "COMPLETE",
    FAILED: "FAILED"
};
export var ChannelOrgCreationType = {
    auto: "auto",
    manual: "manual"
};
export var EmailSendProvider = {
    resend: "resend",
    mock: "mock"
};
export var StatValueType = {
    COUNT: "COUNT",
    AGGREGATE: "AGGREGATE",
    AVERAGE: "AVERAGE"
};
export var TimeOffType = {
    VACATION: "VACATION",
    SICK: "SICK",
    PERSONAL: "PERSONAL",
    JURY_DUTY: "JURY_DUTY",
    VOLUNTEER: "VOLUNTEER",
    BEREAVEMENT: "BEREAVEMENT",
    UNKNOWN: "UNKNOWN"
};
export var FeatureFlagOverrideStatus = {
    enabled: "enabled",
    disabled: "disabled",
    not_set: "not_set"
};
export var NotificationChannelType = {
    EMAIL: "EMAIL",
    SMS: "SMS",
    SLACK: "SLACK",
    ROAM: "ROAM"
};
export var RecordingType = {
    unknown: "unknown",
    internal: "internal",
    external: "external"
};
export var RefuelRequestEntityType = {
    CALENDAR_EVENT: "CALENDAR_EVENT",
    MEETING: "MEETING",
    EMPLOYEE_RELATIONSHIP: "EMPLOYEE_RELATIONSHIP",
    FEEDBACK: "FEEDBACK",
    WINDY_FEEDBACK_CHAT: "WINDY_FEEDBACK_CHAT"
};
export var SmsProvider = {
    TWILIO: "TWILIO",
    MOCK: "MOCK"
};
export var UserSource = {
    email: "email",
    google: "google",
    github: "github"
};
export var SystemTheme = {
    LIGHT: "LIGHT",
    DARK: "DARK"
};
export var WindyChatType = {
    ACCOMPLISHMENT_CONFIRMATION: "ACCOMPLISHMENT_CONFIRMATION",
    ACCOMPLISHMENT_TEAM_REVIEW: "ACCOMPLISHMENT_TEAM_REVIEW",
    EMPLOYEE_FEEDBACK: "EMPLOYEE_FEEDBACK",
    FEEDBACK_MANAGER_REVIEW: "FEEDBACK_MANAGER_REVIEW",
    FEEDBACK_DELIVERY: "FEEDBACK_DELIVERY",
    ACCOUNT_ONBOARDING: "ACCOUNT_ONBOARDING",
    EMPLOYEE_ONBOARDING: "EMPLOYEE_ONBOARDING",
    EMPLOYEE_NUDGE: "EMPLOYEE_NUDGE",
    NEW_YEARS_FEEDBACK_2024: "NEW_YEARS_FEEDBACK_2024",
    SWITCHBOARD: "SWITCHBOARD"
};
export var WindyChatChannel = {
    SLACK: "SLACK",
    ROAM: "ROAM"
};
export var WindyChatMessageSender = {
    USER: "USER",
    BOT: "BOT",
    AUTOMATED: "AUTOMATED"
};
export var WindyChatMessageStatus = {
    PENDING_APPROVAL: "PENDING_APPROVAL",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    AUTO_APPROVED: "AUTO_APPROVED"
};
export var WindyAgentAction = {
    FEEDBACK_MANAGER_REVIEW_MANAGER_HANDLE: "FEEDBACK_MANAGER_REVIEW_MANAGER_HANDLE",
    FEEDBACK_MANAGER_REVIEW_WINDY_HANDLE: "FEEDBACK_MANAGER_REVIEW_WINDY_HANDLE"
};
export var WindyFeedbackChatStatus = {
    PENDING_APPROVAL: "PENDING_APPROVAL",
    ACTIVE: "ACTIVE",
    COMPLETE: "COMPLETE",
    DISMISSED: "DISMISSED",
    EXPIRED: "EXPIRED",
    CANCELLED: "CANCELLED",
    REJECTED: "REJECTED"
};
export var WindyFeedbackChatSource = {
    MANUAL: "MANUAL",
    AUTO: "AUTO",
    ADMIN: "ADMIN"
};
export var WindyFeedbackManagerReviewStatus = {
    PENDING: "PENDING",
    MANAGER_HANDLE: "MANAGER_HANDLE",
    WINDY_HANDLE: "WINDY_HANDLE"
};
export var WindyAccomplishmentChatCandidateStatus = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    APPROVED_EDITED: "APPROVED_EDITED",
    REJECTED: "REJECTED"
};
export var WindyChatNotificationType = {
    REQUEST_RECEIVED: "REQUEST_RECEIVED",
    FIRST_REMINDER: "FIRST_REMINDER",
    FINAL_REMINDER: "FINAL_REMINDER"
};
