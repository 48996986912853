import * as Sentry from "@sentry/remix";

import { ApiError, ApiZodError } from "@wind/errors";
import { TypeUtil } from "@wind/util";

import type FetchConfig from "../models/FetchConfig.js";
import HttpExecutor, { MAX_NETWORK_RETRIES } from "./HttpExecutor.js";

/**
 * Base class for executors using the fetch API.
 *
 * @author @jchaiken1
 */
abstract class FetchExecutor extends HttpExecutor {
  fetch<T>(config: FetchConfig): Promise<T> {
    const init = this.makeRequestInit(config);

    return this.execute(config.url, init, config.body);
  }

  protected abstract override makeRequestInit(config: FetchConfig): RequestInit;

  private async execute<T>(
    url: string,
    config: RequestInit,
    body: unknown | null,
    retries = 0
  ): Promise<T> {
    const requestStart = Date.now();

    let response: Response;
    try {
      response = await fetch(url, config);
    } catch (err: any) {
      if (retries === MAX_NETWORK_RETRIES || !this.isNetworkError(err)) {
        const requestTimeMs = Date.now() - requestStart;
        this.captureFetchError({ err, url, config, requestTimeMs, retries });

        throw err;
      } else {
        return this.execute(url, config, body, retries + 1);
      }
    }

    return this.handleResponse(response, url, body);
  }

  private async handleResponse<T>(response: Response, url: string, body: unknown): Promise<T> {
    if (response.status === 204) {
      // NOTE (jchaiken1) If you call a 204 endpoint, your return type better be undefined.
      return undefined as T;
    } else if (response.status >= 200 && response.status < 300) {
      const body = await response.json();

      if (body.error) {
        throw new Error(body.msg || "Caught server error");
      } else {
        return body;
      }
    } else if (response.status >= 400) {
      throw await this.handleError(response, url, body);
    } else {
      throw new ApiError("Unexpected 300 status code received", response.status, {
        data: {
          status: response.status,
          statusText: response.statusText,
          data: await response.text(),
          url,
          body,
        },
      });
    }
  }

  protected async handleError(response: Response, url: string, body: unknown): Promise<never> {
    const error = await this.buildError(response, url, body);

    Sentry.captureException(error);

    // This message be shown to the user in the error boundary
    const toThrow = new ApiError(undefined, error.httpStatus);

    throw toThrow;
  }

  protected async buildError(response: Response, url: string, body: unknown): Promise<ApiError> {
    let responseBody;
    if (response.headers) {
      const contentType = response.headers.get("content-type");
      if (contentType?.includes("application/json")) {
        responseBody = await response.json();
      } else {
        responseBody = await response.text();
      }
    }

    const context = {
      url,
      body,
      data: responseBody,
    };

    let error = new ApiError(response.statusText, response.status, context);
    if (response.status > 500 || response.status < 400) {
      // 300 or 501+ -- error didn't come from the api
      error = new ApiError(response.statusText, response.status, context);
    } else {
      // error probably came from us (maybe express 404)
      const fallbackMsg = `Unexpected server error (${response.status}): ` + response.statusText;
      if (TypeUtil.isString(responseBody)) {
        // <html> or <!DOCTYPE
        if (responseBody.startsWith("<html") || responseBody.startsWith("<!DOCTYPE")) {
          error = new ApiError(fallbackMsg, response.status, context);
        } else {
          error = new ApiError(responseBody || fallbackMsg, response.status, context);
        }
      } else {
        const json = responseBody || {};
        const msg = json.message || json.error || fallbackMsg;
        const issues = json.issues;

        if (issues && response.status === 400) {
          error = new ApiZodError(msg, response.status, issues, context);
        } else {
          error = new ApiError(msg, response.status, context);
        }
      }
    }

    return error;
  }

  private isNetworkError(error: Error): boolean {
    // Check for Node.js error codes
    const code = (error as any).code;
    if (code) {
      const networkErrorCodes = new Set([
        "ETIMEDOUT",
        "ECONNRESET",
        "ECONNREFUSED",
        "ENOTFOUND",
        "ENETUNREACH",
        "EHOSTUNREACH",
        "EPIPE",
      ]);
      if (networkErrorCodes.has(code)) {
        return true;
      }
    }

    // Check for TypeError (browser throws these for network errors)
    if (error instanceof TypeError) {
      return true;
    }

    // Check common network error messages
    const message = error.message.toLowerCase();
    return (
      message.includes("network") ||
      message.includes("fetch failed") ||
      message.includes("failed to fetch") ||
      message.includes("connection") ||
      message.includes("offline") ||
      message.includes("timeout") ||
      message.includes("abort")
    );
  }
}

export default FetchExecutor;
