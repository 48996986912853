import { forwardRef, type CSSProperties, type ReactNode } from "react";

import cn from "../../util/cn.js";
import { GenericStyles, type GenericStylesProps } from "./genericStyles.js";

interface Props extends GenericStylesProps {
  id?: string;
  onClick?: () => void;
  children: ReactNode;
  className?: string;
  gapNone?: boolean;
  gapXs?: boolean;
  gapSm?: boolean;
  gapMd?: boolean;
  gapLg?: boolean;
  alignStart?: boolean;
  alignCenter?: boolean;
  alignEnd?: boolean;
  alignStretch?: boolean;
  alignBetween?: boolean;
  justifyBetween?: boolean;
  divide?: boolean;
  style?: CSSProperties;
}

const VStack = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      onClick,
      alignCenter,
      alignEnd,
      alignStart,
      alignStretch,
      alignBetween,
      justifyBetween,
      gapNone,
      gapSm,
      gapMd,
      gapLg,
      gapXs,
      className,
      children,
      style,
      divide = false,
      ...styles
    },
    ref
  ) => {
    return (
      <div
        id={id}
        ref={ref}
        onClick={onClick}
        className={cn(
          "items-stretch gap-4 flex flex-col",
          {
            "items-start": alignStart,
            "items-center": alignCenter,
            "items-end": alignEnd,
            "items-stretch": alignStretch,
            "items-between": alignBetween,
            "justify-between": justifyBetween,
            "gap-y-0": gapNone,
            "gap-y-1": gapXs,
            "gap-y-2": gapSm,
            "gap-y-4": gapMd,
            "gap-y-8": gapLg,
            "divide-border gap-0 divide-y": divide,
          },
          GenericStyles(styles),
          className
        )}
        style={style}
      >
        {children}
      </div>
    );
  }
);

export default VStack;
